import { IEnvironment } from '@miks-it-accounts/models';
import { defaultBaseConfig } from './base-config';

export const environment: IEnvironment = {
  ...defaultBaseConfig,
  bffApiBasePath: 'https://apm.meeco.me/stage/miksit-bff',
  subscriptionKey: '8bea5f7e409845eba9aeb21c60284fb5',
  production: true,
  stripePublishableKey:
    'pk_test_51IRDS3JcxOGYT5wifukmvGieUJR8MQSFomau5Hi8QO5mu4vpQ9GLlxHDbJjkYCMSC5B0MldqmYsLiBQZ6mTaU8Tl00L0IwxGdJ',
  msalConfig: {
    clientId: '9525eafd-58d7-4e62-b6e6-a0dc6ada928d',
    authority:
      'https://meecoid.b2clogin.com/meecoid.onmicrosoft.com/B2C_1_miksit-signin-signup',
    knownAuthorities: ['meecoid.b2clogin.com'],
    scopes: [
      'https://meecoid.onmicrosoft.com/api/meeco-vault.user-impersonation',
      'https://meecoid.onmicrosoft.com/api/meeco-keystore.user-impersonation',
    ],
    loginDomainHint: 'miksit-signin-signup',
    resetPasswordDomainHint: 'miksitid_reset_password',
  },
};
